import PropTypes from "prop-types";
import { useState } from "react";
import { useLocation } from 'react-router-dom'
// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
// import SearchSection from './SearchSection';
//import LocalizationSection from './LocalizationSection';
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";

// assets
import { IconMenu2 } from "@tabler/icons";
import { useEffect } from "react";
import { getNotPublishedCampaigns } from "services/campaignService";
import useNotification from "hooks/useNotification";
import { useSelector } from "react-redux";
import EnvironmentComponent from "./EnvironmentComponent/EnvironmentComponent";
import ContentToggle from "components/common/ContentToggle/ContentToggle";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const { showError } = useNotification();
  const location = useLocation()
  const [notPublishedCampaigns, setNotPublishedCampaigns] = useState([]);


  const warning = useSelector((state) => state.warningCampaign);

  // this should be improved by redux
  useEffect(() => {
    getNotPublishedCampaigns()
      .then((result) => {
        setNotPublishedCampaigns(result);
      })
      .catch((err) => showError(err.message));
  }, [location]);

  useEffect(() => {
    if (warning.unSetWarning || warning.setWarning) {
      getNotPublishedCampaigns()
        .then((result) => {
          setNotPublishedCampaigns(result);
        })
        .catch((err) => showError(err.message));
    }
  }, [warning]);

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{ borderRadius: "12px", overflow: "hidden", width: "10vw" }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* check if is on stage or poruction */}
      <EnvironmentComponent />
      <Box  >
        <ContentToggle />
      </Box>
      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />


      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <LocalizationSection />
      </Box> */}

      {/* notification & profile */}
      <NotificationSection notPublishedCampaigns={notPublishedCampaigns} />
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
